<template>
  <div class="agreement">
    <el-dialog title="Please read and agree to the terms" :visible.sync="dialogTableVisible" :before-close="clearagreement">
        <div class="container" v-html="content">
        </div>
        <div class="footer cur" @click="agree"> {{$i18n.locale == 'en' ? 'I have read and agree to the terms' : '我已阅读并同意条款'}} </div>
    </el-dialog>
  </div>
</template>

<script>
import { idoprotocol } from '@/api/controller.js'
 export default {
    data(){
        return{
            dialogTableVisible: true,
            content: ''
        }
    },
    created(){
        this.idoprotocol()
    },
    methods:{
        async idoprotocol(){
            let res = await idoprotocol()
            // console.log(res,'用户协议')
            this.content = unescape(res.data.content)
        },

        clearagreement(val){
            this.$emit('clearment',val)
        },
        agree(){
            this.clearagreement(true)
        },
    }
}
</script>

<style lang="less" scoped>
.agreement{
    /deep/.el-dialog__wrapper{
        .el-dialog{
            width: 771px;
            height: 716px;
            border-radius: 30px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto !important;
            .el-dialog__header{
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                background: #20CDA2;
                .el-icon-close:before{
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                    padding: 1px;
                    color: #FFFFFF;
                }
                .el-dialog__title{
                    font-size: 20px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    &::before{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        line-height: 16px;
                        width: 16px;
                        height: 16px;
                        border: 1.5px solid #fff;
                        border-radius: 50%;
                        font-size: 14px;
                        content: '!';
                        margin-right: 10px;
                    }
                }
            }
            .el-dialog__body{
                height: calc(100% - 80px);
                box-sizing: border-box;
                padding: 10px 20px 20px;
                background: #fff;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                .container{
                    padding-top: 10px;
                    height: 550px;
                    font-size: 14px;
                    color: #111111;
                    line-height: 22px;
                    overflow-y: auto;
                    &::-webkit-scrollbar{ width: 4px; background: #fff; border-radius: 20px; }
                    &::-webkit-scrollbar-thumb{ border-radius: 10px; background: #26E7AE;}
                    &::-o-scrollbar{ width: 4px; background: #fff; border-radius: 20px; }
                    &::-o-scrollbar-thumb{ border-radius: 10px; background: #26E7AE;}
                    &::-ms-scrollbar{ width: 4px; background: #fff; border-radius: 20px; }
                    &::-ms-scrollbar-thumb{ border-radius: 10px; background: #26E7AE;}
                    scrollbar-color: #26E7AE #fff ;//设置滚动条颜色
                    scrollbar-width: thin;
                }
                .footer{
                    margin: 15px auto 0;
                    width: 699px;
                    height: 52px;
                    background: #20CDA2;
                    border-radius: 30px;
                    font-size: 18px;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 52px;
                }
            }
        }
        
    }
}
</style>
