<template>
  <div class="application">
    <div class="Header-information">
      <img class="Header-login" :src="infosimpleList.logo" alt="" srcset="" />
      <div class="header-content">
        <div class="title">
          <div>
            {{ infosimpleList.name }}
            <span style="font-size: 14px; color: #21e2a9"
              >{{ infosimpleList.outputToken }} /
              {{ infosimpleList.inputToken }}</span
            >
          </div>
          <p style="font-size: 12px; margin-top: 10px;">
            Applications close on
            {{ $tool.conversion(infosimpleList.sellEndTime , 7) }} UTC (Block height: {{infosimpleList.sellEndHeight }} )
          </p>
        </div>
        <p class="open-soon">The sale will open soon</p>
        <p class="currency">
          1 {{ infosimpleList.inputToken }} =
          {{ infosimpleList.reverseInitialTokenPrice }}
          {{ infosimpleList.outputToken
          }}<span style=""
            >&emsp;1 {{ infosimpleList.outputToken }} =
            {{ infosimpleList.initialTokenPrice }}
            {{ infosimpleList.inputToken }}</span
          >
        </p>
        <p
          style="
            height: 1px;
            background: #5c5c5c;
            border-radius: 1px;
            margin-bottom: 17px;
          "
        ></p>
        <p style="font-size: 14px">
          Total raise:
          <span style="color: #21daa5"
            >{{ $tool.formatMonenyNumber(infosimpleList.fundraiseGoalInput) }}
            {{ infosimpleList.inputToken }}</span
          >
        </p>
        <p style="font-size: 14px">
          Base allocation:
          <span style="color: #21daa5"
            >{{ infosimpleList.baseAllocation  + " " +  infosimpleList.outputToken}}</span
          >
        </p>
        <div class="timing">
          <p>
            IDO Date：{{ $tool.conversion(infosimpleList.sellStartTime, 7) }} UTC <span class="hengghang"></span> {{ " " + $tool.conversion(infosimpleList.sellEndTime, 7) }} UTC (Block height: {{infosimpleList.sellEndHeight}} )
          </p>
          <div class="share-to">
            <!-- <img src="../../static/images/24gf-network.png" alt="" @click="winopen(infosimpleList.website)"> -->
            <svg-icon
              iconClass="internet"
              @click="winopen(infosimpleList.website)"
            ></svg-icon>
            <img
              v-for="itemlogo in infosimpleList.community"
              :src="
                itemlogo.logo
                  ? itemlogo.logo
                  : require('../../static/images/18.png')
              "
              alt="logo"
              srcset=""
              @click="winopen(itemlogo.url)"
              :key="itemlogo.id"
            />
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="Join-the-Whitelist">
      <p class="join-title">{{ $t("blacklist") }}</p>
      <p class="joincontent">{{ $t("blacklisttitle") }}</p>
    </div>

    <!-- Basic Information -->
    <div class="Join-the-Whitelist" style="height: auto">
      <p class="join-title">{{ $t("essential") }}</p>
      <el-input
        @blur="blurfullName(icipateList.fullName)"
        v-model.trim="icipateList.fullName"
        :placeholder="$t('fullname')"
      ></el-input>
      <p v-if="fullName" style="color: red; font-size: 14px; margin-top: 10px">
        {{ $t("fullname") }}
      </p>
      <p class="hint">
        Please use your real name as you will be required to verify your
        identity (KYC)
      </p>
      <el-input
        v-model.trim="icipateList.email"
        @blur="bluremail(icipateList.email)"
        :placeholder="$t('mailbox')"
      ></el-input>
      <p v-if="email" style="color: red; font-size: 14px; margin-top: 10px">
        {{ $t("mailbox") }}
      </p>
    </div>
    <!-- Social Media -->
    <div class="Join-the-Whitelist Social-Media">
      <p class="join-title">{{ $t("SocialMedia") }}</p>
      <p style="font-size: 14px; margin-top: 10px; margin-bottom: 40px">
        {{ $t("SocialMediatitle") }}
      </p>
      <div
        class="fill-out"
        v-for="item in icipateList.conditionList"
        :key="item.id"
      >
        <div class="title">
          <img :src="item.logo" alt="" />
          <div class="box-item">
            <p class="title">{{ item.socialWebsite }}</p>
            <p style="font-size: 14px">
              {{ item.description }}
            </p>
          </div>
        </div>

        <div class="ChangeState">
          <div v-if="!item.active" @click="openurl(item)">
            {{ $i18n.locale == "en" ? "GO to Feed" : "去完成" }}
          </div>
          <div v-else class="State-active">
            {{ $i18n.locale == "en" ? "GO to Feed" : "去完成" }}
          </div>
          <div class="cut" v-if="!item.answer" @click="didRThis(item)">
            I did this
          </div>
          <el-input
            @blur="blurTwitter(item)"
            v-else
            v-model.trim="item.answer"
            :suffix-icon="item.answer != ' ' ? 'el-icon-success' : ''"
            :placeholder="'Enter your ' + item.socialWebsite + ' handle...'"
          ></el-input>
          <p
            class="eror"
            v-if="item.valueTwitter"
            style="color: red; font-size: 14px"
          >
            Enter your {{ item.socialWebsite }} handle...
          </p>
        </div>
      </div>
    </div>

    <!-- What's your country?  -->
    <div class="Join-the-Whitelist your-country" style="height: auto">
      <p class="join-title">
        {{ $i18n.locale == "en" ? "What's your country?" : "你来自哪个国家？" }}
      </p>
      <el-select
        v-model="icipateList.country"
        :placeholder="$t('country')"
        @change="selectchange"
      >
        <el-option
          v-for="itmser in Countries"
          :label="itmser"
          :value="itmser"
          :key="itmser.id"
        ></el-option>
      </el-select>
      <p v-if="selectIF" style="font-size: 14px; color: red; margin-top: 12px">
        {{ $t("country") }}
      </p>
      <p style="font-size: 12px; margin-top: 12px; margin-bottom: 26px">
        {{ $t("Conditions") }}
        <span @click="btn_emt()" class="cur hover-cur" style="color: #1edfa6">{{
          $t("Conditions2")
        }}</span>
      </p>
      <div class="protocol">
        <i class="icon" @click="pitchon(true)" v-if="pitch_act"></i>
        <img
          v-else
          src="../../static/images/makeanew/succ.png"
          alt=""
          srcset=""
          @click="pitchon(false)"
        />

        <p
          v-if="!service"
          class="cur"
          style="font-size: 14px; margin-left: 12px"
        >
          {{
            $i18n.locale == "en"
              ? "I accept the terms of service"
              : "我接受服务条款"
          }}
        </p>
        <p
          v-else
          class="cur"
          style="font-size: 14px; color: red; margin-left: 12px"
        >
          {{
            $i18n.locale == "en"
              ? "I accept the terms of service"
              : "我接受服务条款"
          }}
        </p>
      </div>
    </div>

    <div class="Submit cur" v-if="recognize" @click="buttomapplic">
      {{ $i18n.locale == "en" ? "Submit your application" : "提交您的申请" }}
    </div>
    <div v-else class="Submit" style="background: #97989a">
      {{
        $i18n.locale == "en"
          ? "You have successfully participated in the event"
          : "您已成功参与活动"
      }}
    </div>

    <!-- agreement -->
    <agreement v-if="if_agreement" @clearment="clearment($event)"></agreement>

    <!-- 置顶 -->
    <img
      class="stick"
      v-if="if_stick"
      src="../../static/images/zhiding.png"
      alt=""
      srcset=""
      @click="BackToThetop()"
    />
  </div>
</template>

<script>
import agreement from "./components/agreement.vue";
import {
  idoparticipate,
  idoinfo,
  idoisParticipated,
  idocountries,
  idosubmit,
  chainNetHeight,
  getSocialWebsiteLogo,
} from "@/api/controller";
export default {
  components: {
    agreement,
  },
  data() {
    return {
      blockhieght: 0,
      pitch_act: true,
      input: "",
      if_agreement: false,
      if_stick: false,
      infosimpleList: {}, //简要信息
      recognize: true,
      Countries: [],
      icipateList: {
        country: "",
        email: "",
        fullName: "",
        conditionList: [],
      },
      fullName: false,
      email: false,
      service: false,
      selectIF: false,
    };
  },
  created() {
    this.idoparticipate();
    this.idoisParticipated();
    this.idoinfo();
  },

  mounted() {
    //addEventlistener 第三个参数必须设置为true
    addEventListener("scroll", this.handleScroll, true);
  },

  methods: {
    selectchange() {
      if (this.icipateList.country) {
        this.selectIF = false;
      } else {
        this.selectIF = true;
      }
    },
    winopen(url) {
      window.open(url);
    },
    didRThis(item) {
      item.answer = " ";
      this.$forceUpdate();
    },

    blurTwitter(value) {
      if (value.answer) {
        value["valueTwitter"] = false;
      } else {
        value["valueTwitter"] = true;
      }
      this.$forceUpdate();
    },

    blurfullName(value) {
      if (value) {
        this.fullName = false;
      } else {
        this.fullName = true;
      }
    },

    bluremail(value) {
      if (value) {
        this.email = false;
      } else {
        this.email = true;
      }
    },

    async buttomapplic() {
      let flag = true;

      if (this.icipateList.country) {
        this.selectIF = false;
      } else {
        flag = false;
        this.selectIF = true;
      }

      if (this.icipateList.fullName) {
        this.fullName = false;
      } else {
        flag = false;
        this.fullName = true;
      }

      if (this.icipateList.email) {
        this.email = false;
      } else {
        flag = false;
        this.email = true;
      }

      this.icipateList.conditionList.forEach((element) => {
        if (element.answer) {
          element["valueTwitter"] = false;
        } else {
          flag = false;
          element["valueTwitter"] = true;
        }
      });

      if (!this.pitch_act) {
        this.service = false;
      } else {
        flag = false;
        this.service = true;
      }

      this.$forceUpdate();
      if (!flag) {
        return false;
      }
      let array = [];
      this.icipateList.conditionList.forEach((item) => {
        let obj = {
          answer: item.answer,
          id: item.id,
          idoUserId: item.idoId,
          socialWebsite: item.socialWebsite,
        };
        array.push(obj);
      });

      let res = await idosubmit({
        country: this.icipateList.country, //国家
        email: this.icipateList.email, // 邮箱
        fullName: this.icipateList.fullName, //全名
        idoId: this.$route.query.id,
        idoState: this.infosimpleList.idoState, //参与状态
        infoList: array,
        userAddress: this.$store.state.address,
      });
      // console.log(res,'提交白名单')
      if (res.code === 0) {
        if (this.$i18n.locale == "en") {
          this.$message({
            message: "Success",
            type: "success",
          });
        } else {
          this.$message({
            message: "提交成功",
            type: "success",
          });
        }
        this.recognize = false;

        setTimeout(() => {
          this.$router.push({
            path: "/newdetails",
            query: { id: this.$route.query.id },
          });
        }, 2000);
      } else {
        if (this.$i18n.locale == "en") {
          this.$message({
            message: "Fail",
            type: "error",
          });
        } else {
          this.$message({
            message: "提交失败",
            type: "error",
          });
        }
      }
    },

    openurl(item) {
      item["active"] = true;
      this.$forceUpdate();
      window.open(item.link);
    },

    async idoparticipate() {
      let res = await idoparticipate(this.$route.query.id);
      if (res.code === 0) {
        if (res.data.conditionList.length > 0) {
          res.data.conditionList.forEach(async (item) => {
            // console.log(item.socialWebsite)
            let trs = await this.btn_logo(item.socialWebsite);
            if (trs.indexOf("http") > -1) {
              item["logo"] = trs;
            } else {
              item["logo"] = "../../static/images/18.png";
            }
          });
        }
        // console.log(res.data, '进入白名单界面信息')
        this.icipateList = res.data;
        this.idocountries();
      }
    },
    async idocountries() {
      let res = await idocountries();
      // console.log(res, '国家选择')
      if (res.code === 0) {
        this.Countries = res.data;
      }
    },
    async btn_logo(site) {
      let res = await getSocialWebsiteLogo(site);

      if (res.code === 0) {
        if (res.data.indexOf("http") > -1) {
          return res.data;
        }
      }
    },
    async idoinfo() {
      let res = await idoinfo(this.$route.query.id);
      if (res.code === 0) {
        if (res.data.community.length > 0) {
          res.data.community.forEach(async (element) => {
            element["logo"] = await this.btn_logo(element.name);
            this.$forceUpdate();
          });
        }

        this.chainNetHeight(res.data.chainId)
        // console.log(res.data,'详情')
        this.infosimpleList = res.data;
      }
    },
    async chainNetHeight(chainId){
      let res = await chainNetHeight(chainId)
      this.blockhieght = res.data
    },
    async idoisParticipated() {
      let res = await idoisParticipated(this.$route.query.id);
      // console.log(res, '是否参与报名')
      if (res.code === 0) {
        if (res.data) {
          this.pitch_act = false;
          this.recognize = false;
        } else {
          this.recognize = true;
        }
      }
    },

    handleScroll() {
      let scrollTop = document.querySelector(".el-main").scrollTop;
      if (scrollTop < 100) {
        this.if_stick = false;
      } else {
        this.if_stick = true;
      }
    },

    BackToThetop() {
      // 回到顶部
      let scrollTop = document.querySelector(".el-main").scrollTop;
      let setint = setInterval(() => {
        if (scrollTop <= 1) {
          // console.log('结束')
          clearInterval(setint);
        } else {
          document.querySelector(".el-main").scrollTop = scrollTop -= 20;
        }
      }, 10);
    },

    btn_emt() {
      this.if_agreement = true;
    },

    clearment(val) {
      // console.log(val,'我已同意条约')
      if (val) {
        this.pitch_act = false;
      }
      this.if_agreement = false;
    },

    pitchon(e) {
      this.pitch_act = !e;

      if (!this.pitch_act) {
        this.service = false;
      } else {
        this.service = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.application {
  .stick {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 5%;
    right: 3%;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
      background: #22dda7;
    }
  }
  .Submit {
    margin-top: 30px;
    height: 56px;
    background: #1edfa6;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fefefe;
    text-align: center;
    line-height: 56px;
  }
  /deep/.Join-the-Whitelist {
    // height: 126px;
    background: linear-gradient(0deg, #2d3138, #3a3f45);
    border-radius: 30px;
    box-sizing: border-box;
    padding: 28px 33px;
    margin-top: 20px;
    .hover-cur:hover {
      text-decoration: underline;
    }
    .protocol {
      display: flex;
      align-items: center;
      .icon {
        cursor: pointer;
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 1px solid #1edfa6;
        border-radius: 50%;
      }
      img {
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }
    .el-input__inner {
      height: 56px;
      background: transparent;
      border: 1px solid #727272;
      border-radius: 10px;
      color: #fefefe;
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        border-color: #fefefe;
        color: #606266;
      }

      &:hover,
      &:active {
        border-color: #fefefe;
        // color: #fefefe;
      }
    }
    .hint {
      font-size: 12px;
      // color: #566288;
      margin-top: 12px;
      margin-bottom: 25px;
    }
    .el-select {
      width: 100%;
    }
    .join-title {
      font-size: 16px;
      font-weight: bold;
      color: #fefefe;
      margin-bottom: 22px;
    }
    .joincontent {
      margin-top: 18px;
      font-size: 14px;
      // color: #838FBA;
    }
  }
  /deep/.Social-Media {
    height: auto;
    .fill-out {
      margin-bottom: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
      }
      .ChangeState {
        display: flex;
        align-items: center;
        position: relative;
        .eror {
          position: absolute;
          right: 0;
          bottom: -20px;
        }
        .el-input {
          margin-left: 25px;
          width: 250px;
          background: initial;
          .el-input__inner {
            width: 100%;
            height: 48px;
            border-radius: 10px;
            font-size: 14px;
          }
          .el-input__suffix {
            color: #1edfa6;
          }
        }
        .State-active {
          background: #5b5c5e;
        }
        div {
          width: 150px;
          height: 48px;
          border-radius: 10px;
          font-size: 14px;
          background: #1edfa6;
          color: #fefefe;
          text-align: center;
          line-height: 48px;
          cursor: pointer;
        }
        .cut {
          border: 1px solid #1edfa6;
          background: initial;
          color: #1edfa6;
          margin-left: 25px;
        }
      }
      img {
        width: 48px;
        height: 48px;
      }
      .box-item {
        margin-left: 18px;
        font-size: 16px;
        font-weight: bold;
        // color: #ffffff;

        .title {
          color: #fefefe;
        }
      }
    }
  }
  .Header-information {
    box-sizing: border-box;
    width: 100%;
    height: 237px;
    background: linear-gradient(0deg, #2d3138, #3a3f45);
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 20px;
    position: relative;
    .share-to {
      display: flex;
      align-items: center;
      // position: absolute;
      // bottom: 38px;
      // right: 30px;
      img{
        width: 28px;
      }
      .svg-icon {
        font-size: 28px;
        cursor: pointer;

        &:hover {
          color: #fefefe;
        }
      }
    }
    .header-content {
      padding-left: 16px;
      width: 100%;
      .open-soon {
        font-size: 14px;
        // color: #8A97C1;
        margin-top: 12px;
      }
      .timing {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .hengghang{
          display: inline-block;
          width: 30px;
          height: 1px;
          background: rgb(92, 92, 92);
          position: relative;
          top: -4px;
        }
        p{
          font-size: 14px;
          margin-top: 13px;
        }
      }
      .currency {
        font-size: 16px;
        font-weight: bold;
        color: #21e2a9;
        margin: 22px 0 15px 0;
      }
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #fefefe;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        span {
          margin-left: 10px;
        }
      }
    }
    .Header-login {
      width: 212px;
      height: 206px;
      border-radius: 30px;
    }
  }
}

.isMobile .application {
  .Header-information {
    height: auto;
    flex-wrap: wrap;
  }
  .Header-information .share-to {
    position: unset;
    margin: 20px auto;
  }

  .Header-login {
    margin: 20px auto;
  }

  .header-content {
    padding: 0;

    .title {
      flex-wrap: wrap;
      width: 100%;
    }

    .title p {
      position: unset;
    }
  }
  .Social-Media .fill-out {

    flex-wrap: wrap;

    .ChangeState {
      width: 100%;
      flex-wrap: wrap;

      div {
        margin: 10px 0;
        width: 100%;
      }
    }
  }
}
</style>
